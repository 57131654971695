export function getSlice(array, request){
    return array.find(o => o.slice_type === request);
}

export function createLinks(pages) {
    const pagesArr = [];
    pages.forEach((item) => {
        const pageId = item.id;
        pagesArr.push(pageId);
    })
    return pagesArr;
}