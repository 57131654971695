import React, {useEffect, useState} from 'react';
import Section from "../section/section";
import "./certificate.scss";

export default function Certificate(props) {
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <Section id={data?.slice_type} type={'flex'}>
            <div className={'section-left'}>
                <h2 className={'section-flex-title'}>{data.primary?.our_certificates[0]?.text}</h2>
                <p className={'about-company-description'}>{data.primary?.description[0]?.text}</p>
            </div>
            <div className={'section-right partners-items-container'}>
                {
                    data?.items?.map((item, index) =>
                        <div className={'certificate-item'} key={index}>
                            <img src={item?.certificate_image?.url} alt={item?.certificate_image?.alt}/>
                        </div>
                    )
                }
            </div>
        </Section>
    )
}