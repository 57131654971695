import React, {useEffect, useState} from 'react';
import "./loader.scss"
import {CSSTransition} from "react-transition-group";
import {ReactComponent as LoaderImg} from "../../img/loading.svg";

export default function Loader(props) {
    const [isDisplayed, setDisplayed] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setDisplayed(false);
        }, 750);
    }, [])

    return(
        <>
            {
                <CSSTransition
                    classNames="fade"
                    timeout={300}
                    enter
                    exit
                    mountOnEnter
                    unmountOnExit
                    in={isDisplayed}
                >
                    <div className="loader-overlay">
                        <div className="loader-logo">
                            <LoaderImg/>
                        </div>
                    </div>
                </CSSTransition>
            }
        </>
    )
}