import React from 'react';
import Section from "../../components/section/section";
import "./notFound.scss"
import {Link} from "react-router-dom";

export default function NotFoundPage(props){
    return(
        <Section className={"not-found-page"}>
            <div className={'not-found'}>
                <span className={'not-found-code'}>404</span>
            </div>
             <p className={'not-found-content'}>Page not found.</p>
            <Link className={'not-found-home'} to={"/"}>Home</Link>
        </Section>
    )
}