import React, {useEffect, useState} from 'react';
import "./product-page.scss";
import Table from "../../components/table/table";
import Section from "../../components/section/section";
import {getSlice} from "../../functions";

export default function Product(props) {
    const [data, setData] = useState(props.data.data);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setData(props.data.data);
    }, [props.data])

    return (
        <div className="product-container">
            <div className={"product-information"}>
                <div className="product-information-image">
                    <img alt={data?.product_image?.url} src={data?.product_image?.url}/>
                </div>
                <div className="product-information-content">
                    <h2 className="product-information-title">
                        {data?.product_name[0]?.text}
                    </h2>
                    {
                        data?.product_description.map((item, index) =>
                            <p className="product-information-paragraph" key={index}>{item?.text}</p>
                        )
                    }
                </div>
            </div>
            <Section className={'table-section'}>
                {
                    data &&
                    <Table mainCharacters={getSlice(data?.body, "table_of_main_characteristics")}
                           customCharacters={getSlice(data?.body, "table_of_custom_characteristics")}/>
                }
            </Section>
        </div>
    )
}