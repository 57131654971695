import React from 'react';
import "./table.scss"
import Tooltip from "../tooltip/tooltip";

export default function Table(props) {
    return (
        <div className={'table-container'}>
            <div className={'table table-main'}>
                {
                    props?.mainCharacters &&
                    <div>
                        <h2>The main characteristics</h2>
                        {
                            props?.mainCharacters?.items.map((item, index) =>
                                <div key={index} className={'table-row'}>
                                    <div className={'table-col table-name'}>{item?.characteristic_name[0]?.text}
                                        {
                                            item?.tooltip.length > 0 &&
                                            <Tooltip text={item?.tooltip[0]?.text}/>
                                        }
                                    </div>
                                    <div className={'table-col table-value'}>{item?.characteristic_value[0]?.text}</div>
                                </div>
                            )
                        }
                    </div>
                }
            </div>

            <div className={'table table-main'}>
                {
                    props?.customCharacters &&
                    <div>
                        <h2>Custom characteristics</h2>
                        {
                            props?.customCharacters?.items.map((item, index) =>
                                <div key={index} className={'table-row'}>
                                    <div className={'table-col table-name'}>{item?.characteristic_name[0]?.text}
                                        {
                                            item?.tooltip.length > 0 &&
                                            <Tooltip text={item?.tooltip[0]?.text}/>
                                        }
                                    </div>
                                    <div className={'table-col table-value'}>{item?.characteristic_value[0]?.text}</div>
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    )
}