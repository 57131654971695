import React, {useEffect} from 'react';
import {usePrismicDocumentByUID} from "@prismicio/react";
import ProductsSlider from "../../components/products-slider/products-slider";
import {getSlice} from "../../functions";
import HeroBanner from "../../components/hero-banner/hero-banner";
import AboutCompany from "../../components/about-company/about-company";
import Partners from "../../components/partners/partners";
import BigSlider from "../../components/big-slider/big-slider";
import Certificate from "../../components/certificate/certificate";
import FaqComponent from "../../components/faq/faq";
import Reviews from "../../components/reviews/reviews";

export default function Home(props){
    const [document] = usePrismicDocumentByUID('home_page', 'home-page');

    useEffect(() => {
        console.log(document);
    }, [document]);

    return(
        document ?
            <div>
                <HeroBanner data={document}/>
                <ProductsSlider products={props.products} data={getSlice(document.data.body, "cards_carousel")}/>
                <AboutCompany data={getSlice(document.data.body, "about_company")}/>
                <Partners data={getSlice(document.data.body, "our_partners")}/>
                <BigSlider data={getSlice(document.data.body, "cards_carousel")}/>
                <Certificate data={getSlice(document.data.body, "our_certificate")}/>
                <FaqComponent data={getSlice(document.data.body, "faq_section")}/>
                <Reviews data={getSlice(document.data.body, "reviews")}/>
            </div>
            :
            <p></p>
    )
}