import React, {useEffect, useState} from 'react';
import Section from "../section/section";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./big-slider.scss";
import {ReactComponent as Left} from "../../img/icons/arrow-left.svg";
import {ReactComponent as Right} from "../../img/icons/arrow-right.svg";

export default function BigSlider(props) {
    const [data, setData] = useState(props.data);
    const [currentSlide, setCurrentSlide] = useState(0);
    const customSlider = React.createRef();
    const [imagePadding, setImagePadding] = useState(`300`);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const gotoNext = () => {
        customSlider.current.slickNext();
    }

    const gotoPrev = () => {
        customSlider.current.slickPrev();
    }

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 1200) {
            setImagePadding(`${(windowWidth - 1170) / 2}px`);
        }else{
            setImagePadding(`380px`)
        }
    }

    useEffect(() => {
        handleResize();
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: imagePadding,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    centerMode: false,
                    centerPadding: '0px',
                }
            }
        ],
        afterChange: current => setCurrentSlide(current)
    };
    window.addEventListener('resize', handleResize)
    return (
        <>
            <Section id={data?.slice_type} className={'products-slider-container big-slider'}>
                <div className={'big-slider-container'}>
                    <Slider {...settings} ref={customSlider}>
                        {
                            props.data?.items.map((item, index) =>
                                <div key={index} className={'big-slider-item'}>
                                    <img alt={item?.image?.alt} src={item?.image?.url}/>
                                </div>
                            )
                        }
                    </Slider>
                </div>
                <div className={'big-slider-arrows-container'}>
                    <div className={'big-slider-counter'}>
                        0{currentSlide + 1}/0{props.data?.items.length}
                    </div>
                    <div className={'big-slider-arrows'}>
                        <div className={'products-slider-arrows'}>
                            <Left onClick={() => gotoPrev()}/>
                            <Right onClick={() => gotoNext()}/>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}