import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Section from "../section/section";
import {ReactComponent as Left} from "../../img/icons/arrow-left.svg"
import {ReactComponent as Right} from "../../img/icons/arrow-right.svg"
import "./products-slider.scss"
import {Link} from "react-router-dom";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

export default function ProductsSlider(props) {
    const [data, setData] = useState(props.data);
    const [products, setProducts] = useState(props.products);
    const customSlider = React.createRef();

    const gotoNext = () => {
        customSlider.current.slickNext();
    }

    const gotoPrev = () => {
        customSlider.current.slickPrev();
    }

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    useEffect(() => {
        setProducts(props.products);
    }, [props.products])


    return (
        <Section id={data?.slice_type} className={'products-slider-container'} heading={'Our products'} >
            {
                <Slider className={'products-slider'} {...settings} ref={customSlider}>
                    {
                        products &&
                        products.map((item, index) =>
                            <Link to={item.id} key={index} className={'products-slider-item'}>
                                <div className={'products-slider-item-image'}>
                                    <img alt={item.data?.product_image?.alt} src={item.data?.product_image?.url}/>
                                </div>
                                <p className={'products-slider-name'}>{item.data?.product_name[0]?.text}</p>
                            </Link>
                        )
                    }
                </Slider>
            }
            <div className={'products-slider-arrows'}>
                <Left onClick={() => gotoPrev()} />
                <Right onClick={() => gotoNext()} />
            </div>
        </Section>
    )
}