import React, {useEffect, useState} from 'react';
import {useAllPrismicDocumentsByType, usePrismicDocumentByUID} from '@prismicio/react'
import Home from "./templates/home/home";
import "./scss/main.scss"
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import Product from "./templates/product-page/product-page";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Loader from "./components/loader/loader";
import NotFoundPage from "./templates/notFound/notFound";

export default function App() {
    const [products] = useAllPrismicDocumentsByType('product');
    const SITE_KEY = "6Lf1LboiAAAAAAkfCgB3UpyKGS1UYltPseieMgPK";

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    return (
        <Router >
            <Routes>
                <Route exact path="*" element={<DefaultApp siteKey={SITE_KEY} products={products}/>}/>
            </Routes>
        </Router>
    )
}

function DefaultApp(props) {
    let location = useLocation();
    const [document] = usePrismicDocumentByUID('home_page', 'home-page');
    const [products, setProducts] = useState([]);

    useEffect(() => {
        props.products && setProducts(props.products);
    }, [props.products])

    return (
        <div className={'main-container'}>
            <div className={'main-container-inner'}>
                {document && (
                    <>
                        <TransitionGroup>
                            <CSSTransition
                                key={location.pathname}
                                classNames="fade"
                                timeout={300}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className={'fade'}>
                                    <Loader/>
                                    <Header location={location}/>
                                    <Routes location={location}>
                                        <Route path="/" element={<Home products={props.products}/>}/>
                                        {
                                            products &&
                                            products.map(product =>
                                                <Route key={product.id} path={product.id}
                                                       element={<Product data={product}/>}/>
                                            )
                                        }
                                        <Route path="*" element={<NotFoundPage/>} />
                                    </Routes>
                                    <Footer recaptcha={props.siteKey} />
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </>
                )}
            </div>
        </div>
    )
}