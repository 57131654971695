import React, {useEffect, useState} from 'react';
import {usePrismicDocumentByUID} from "@prismicio/react";
import Section from "../section/section";
import "./footer.scss"
import {getSlice} from "../../functions";
import ValidationInput from "../inputValid/inputValid";
import axios from 'axios';
import {CSSTransition} from "react-transition-group";


const API_PATH = 'https://mercuryx.com.ua/callback.php';

export default function Footer(props) {
    const [data, setData] = useState({});
    const [document] = usePrismicDocumentByUID('footer', 'footer');
    const [form, setForm] = useState({});
    const [footerLinks, setFooterLinks] = useState({});
    const [footerSocial, setFooterSocial] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [formStatus, setFormStatus] = useState("");
    const [isMessageSent, setIsMessageSent] = useState(false);

    useEffect(() => {
        setData(document);
        document && setForm(getSlice(document?.data?.body, "field_for_forma"));
        document && setFooterLinks(getSlice(document?.data?.body, "footer_address_email_phone"))
        document && setFooterSocial(getSlice(document?.data?.body, "footer_social_links"))
    }, [document])

    const contactForm = (e) => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute("6Lf1LboiAAAAAAkfCgB3UpyKGS1UYltPseieMgPK", {action: 'submit'}).then(token => {
                setFormLoading(!formLoading);
                const {Name, Email, Phone, recipient} = e.target.elements;
                let data = {
                    name: Name.value,
                    email: Email.value,
                    phone: Phone.value,
                    recipient: recipient.value,
                    captcha: token
                };
                axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: {'content-type': 'application/json'},
                    data: data
                })
                    .then((r) => {
                        console.log(r);
                        if (r.status === 200 && r.data.sent) {
                            setIsMessageSent(true);
                            setFormStatus(r.data.message);
                        }
                    })
                    .catch(error => {
                        console.log({error: error.message})
                        setIsMessageSent(false);
                        setFormStatus(error.message);
                        setTimeout(() => {
                            setFormStatus("");
                        }, 3000)
                    });
            });
        });
    };


    return (
        data ?
            <div>
                <div className={'footer'}>
                    <Section type={'flex'} className={'footer-input-section'} id={'contact-us'}>
                        <div className={'section-left'}>
                            <h2 className={'section-flex-title'}>{data?.data?.get_in_touch[0]?.text}</h2>
                            <p className={'about-company-description'}>{data?.data?.leave_your_contacts[0]?.text}</p>
                        </div>
                        <div className={'section-right footer-inputs-container'}>
                            <form onSubmit={contactForm} className={'footer-inputs'}>
                                {
                                    form &&
                                    form.items?.map((item, index) =>
                                        <ValidationInput
                                            name={item?.field[0]?.text}
                                            required={item?.required}
                                            key={index}
                                            placeholder={item?.field[0]?.text + (item?.required ? '*' : '')}
                                        />
                                    )
                                }
                                <p className={'form-captcha'}>This site is protected by reCAPTCHA and the Google
                                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                                <div className={'input-submit'}>
                                    {
                                        !isMessageSent &&
                                        <button type={"submit"} className={'footer-inputs-button'}>
                                            {
                                                <span>{form?.primary?.button[0]?.text}</span>
                                            }
                                        </button>
                                    }
                                    {
                                        <input type="hidden" name="recipient"
                                               defaultValue={form?.primary?.email_address_of_recipient[0]?.text}/>
                                    }
                                    {
                                        <CSSTransition
                                            classNames="fade"
                                            timeout={300}
                                            enter
                                            exit
                                            mountOnEnter
                                            unmountOnExit
                                            in={Boolean(formStatus)}
                                        >
                                            <p>{formStatus}</p>
                                        </CSSTransition>
                                    }
                                </div>
                            </form>
                        </div>
                    </Section>
                </div>
                <footer className={'footer-bottom'}>
                    <Section type={'flex'} className={"footer-items"}>
                        <div className={'footer-logo'}>
                            <img alt={footerLinks?.primary?.dark_logo?.alt} src={footerLinks?.primary?.dark_logo?.url}/>
                        </div>
                        <div className={'footer-bottom-links'}>
                            {
                                footerLinks &&
                                footerLinks?.items?.map((item, index) =>
                                    <div className={'footer-bottom-item'} key={index}>
                                        <p className={'footer-bottom-title'}>{item?.title_field[0]?.text}</p>
                                        {
                                            item?.content.map((content, index) =>
                                                <a
                                                    target={content?.spans[0]?.data?.target}
                                                    href={content?.spans[0]?.data?.url}
                                                    key={index}
                                                    className={'footer-bottom-link'}
                                                >
                                                    {content?.text}
                                                </a>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </Section>
                    <Section type={'flex'} className={'footer-rights'}>
                        <div className={'footer-rights-container'}>
                            {
                                document &&
                                document.data?.rights.map((item, index) =>
                                    <p key={index} className={'footer-rights-stamp'}>{item.text}</p>
                                )
                            }
                            <div className={'footer-rights-links'}>
                                {
                                    footerSocial &&
                                    footerSocial?.items?.map((item, index) =>
                                        <a href={item?.link_item[0]?.spans[0]?.data?.url}
                                           key={index}>{item?.link_item[0]?.text}</a>
                                    )
                                }
                            </div>
                        </div>
                    </Section>
                </footer>
            </div>
            :
            <div>loading</div>
    )
}