import React from 'react';
import './section.scss'
import classNames from "classnames";

export default function Section(props) {

    return (
        <section id={props?.id} className={classNames(props.className, 'section')}>
            {
                props.type === 'flex' ?
                    <div className={'section-outer'}>
                        <div className={'section-body container section-flex'}>
                            {props.children}
                        </div>
                    </div>
                    :
                    <div className={'section-outer'}>
                        {
                            props.heading &&
                            <h2 className={'section-heading container'}>{props.heading}</h2>
                        }
                        <div className={'section-body container'}>
                            {props.children}
                        </div>
                    </div>
            }
        </section>
    )
}