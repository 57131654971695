import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import {PrismicProvider} from '@prismicio/react'
import {client} from './prismic'

ReactDOM.render(
    <PrismicProvider client={client}>
        <App/>
    </PrismicProvider>,
    document.getElementById('root')
)