import React, {useEffect, useState} from 'react';
import Section from "../section/section";
import Faq from "react-faq-component";
import "./faq.scss";

export default function FaqComponent(props) {
    const [data, setData] = useState(props.data);
    const [faqData, setFaqData] = useState({});

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        let array = [];
        data?.items.forEach(item =>
            array.push(
                {
                    title: item?.rich_text_field[0]?.text,
                    content: item?.text[0]?.text
                }
            )
        )
        setFaqData({rows: array});
    }, [data])

    const config = {
        animate: true,
        arrowIcon: "+",
        tabFocus: true
    };

    return (
        <Section id={data?.slice_type} type={'flex'}>
            <div className={'section-left'}>
                <h2 className={'section-flex-title'}>{data.primary?.title[0]?.text}</h2>
                <p className={'about-company-description'}>{data.primary?.description[0]?.text}</p>
            </div>
            <div className={'section-right faq-items-container'}>
                {
                    faqData &&
                    <Faq
                        data={faqData}
                        config={config}
                    />
                }
            </div>
        </Section>
    )
}