import React from 'react';
import InputMask from 'react-input-mask';
import "./inputValid.scss";

export default function ValidationInput(props) {


    return (
        <div className={'input-valid'}>
            {
                props.name === 'Phone' ?
                    <input type="tel"
                           required={props.required}
                           placeholder={props.placeholder}
                           name={props.name}
                           className={props.required ? "input-required" : ""}
                    />
                    // <InputMask {...props} mask="+380\ (99) 999 99 99" maskChar="_"/>
                    :
                    <input className={props.required ? "input-required" : ""}
                           name={props.name}
                           required={props.required}
                           placeholder={props.placeholder}/>
            }
        </div>
    )
}