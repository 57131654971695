import React, {useState} from 'react';
import "./burger.scss";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {ReactComponent as BurgerIcon} from "../../img/icons/burger.svg";
import {ReactComponent as CloseIcon} from "../../img/icons/close.svg";
import {ReactComponent as BlackLogo} from "../../img/blackLogo.svg";
import {Link, useLocation} from "react-router-dom";

export default function Burger(props) {
    let location = useLocation();
    const [isOpen, setOpen] = useState(false);

    const anchorFunc = () => {
        setOpen(false);
        return 200;
    }

    return (
        <>
            <div onClick={() => setOpen(!isOpen)} className={'burger-button'}>
                <BurgerIcon/>
            </div>
            <div className={classNames("burger-container", isOpen && 'burger-container-active')}>
                <div className={"burger-header header-inner container"}>
                    <div className={'header-logo'}>
                        <BlackLogo/>
                    </div>
                    <div onClick={() => setOpen(!isOpen)} className={'burger-close'}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={'burger-nav'}>
                    <ul className={'burger-menu'}>
                        {
                            location.pathname === "/" ?
                                props?.data.map((link, index) =>
                                    <li key={index}><AnchorLink offset={() => anchorFunc(200)} href={link.link}>{link.text}</AnchorLink></li>
                                )
                                :
                                props?.data.map((link, index) =>
                                    link.text !== "Contact us" ?
                                        <li key={index}><Link to="/" state={{section: link.link}}>{link.text}</Link></li>
                                        :
                                        <li key={index}><AnchorLink offset={() => anchorFunc(200)} href={link.link}>{link.text}</AnchorLink></li>
                                )
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}