import React, {useEffect, useState} from 'react';
import Section from "../section/section";
import "./about-company.scss"

export default function AboutCompany(props) {
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    return (
        <Section id={data?.slice_type} type={'flex'}>
            <div className={'section-left'}>
                <h2 className={'section-flex-title'}>{data?.primary?.title_section[0]?.text}</h2>
                <div className={'about-company-content'}>
                    <p className={'about-company-description'}>{data?.primary?.description_section[0]?.text}</p>
                    <div className={'about-company-advantages-list'}>
                        {
                            data?.primary?.list_of_advantages.map((item, index) =>
                                <p key={index} className={'about-company-advantages'}>{item.text}</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={'section-right'}>
                <img src={data?.primary?.image_of_about_section?.url} alt={data?.primary?.image_of_about_section?.alt}/>
                <div className={'about-company-statistics'}>
                    {
                        data?.items.map((item, index) =>
                            <div key={index} className={'about-company-statistics-item'}>
                                <div
                                    className={'about-company-statistics-value'}>{item?.statistic_value[0]?.text}</div>
                                <div
                                    className={'about-company-statistics-description'}>{item?.statistic_description[0]?.text}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </Section>
    )
}