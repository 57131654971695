import React, {useEffect} from 'react';
import {ReactComponent as Logo} from "../../img/logo.svg";
import './header.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Burger from "../burger/burger";
import {Link} from "react-router-dom";

export const links = [
    {text: "Products", link: "#cards_carousel"},
    {text: "About", link: "#about_company"},
    {text: "Partners", link: "#our_partners"},
    {text: "FAQ", link: "#faq_section"},
    {text: "Reviews", link: "#reviews"},
    {text: "Contact us", link: "#contact-us"}
];

export default function Header(props) {

    useEffect(() => {
        setTimeout(() => {
            if (props.location.pathname === "/" && props.location.state?.section) {
                const elem = document.querySelector(props.location.state?.section);
                if (elem) {
                    const y = elem.getBoundingClientRect().top + window.pageYOffset - 200;
                    elem && window.scrollTo({top: y, behavior: 'smooth'});
                    window.history.replaceState({}, document.title);
                }
            }
        }, 500)
    }, [])

    return (
        <header className={'header'}>
            <div className={'header-inner container'}>
                <div className={'header-logo'}>
                    <Link to={'/'}>
                        <Logo/>
                    </Link>
                </div>
                <ul className={'header-nav'}>
                    {
                        props.location.pathname === "/" ?
                            links.map((link, index) =>
                                <AnchorLink key={index} offset={200} href={link.link}>{link.text}</AnchorLink>
                            )
                            :
                            links.map((link, index) =>
                                link.text !== "Contact us" ?
                                    <Link key={index} to="/" state={{section: link.link}}>{link.text}</Link>
                                    :
                                    <AnchorLink key={index} offset={200} href={link.link}>{link.text}</AnchorLink>
                            )
                    }
                </ul>
                <Burger data={links} location={props?.location}/>
            </div>
        </header>
    )
}