import React, {useState} from 'react';
import {ReactComponent as TooltipIcon} from "../../img/icons/tooltip.svg";
import {CSSTransition} from 'react-transition-group';
import "./tooltip.scss"

export default function Tooltip(props) {
    const [show, setShow] = useState(false);

    return (
        <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className={'tooltip'}>
            <span><TooltipIcon/></span>
            <CSSTransition
                in={show}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <div className={'tooltip-container'}>
                    {props.text}
                </div>
            </CSSTransition>
        </div>
    )
}