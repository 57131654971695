import React, {useEffect, useState} from 'react';
import "./hero-banner.scss"

export default function HeroBanner(props) {
    const [data, setData] = useState(props.data?.data);

    useEffect(() => {
        setData(props.data?.data);
    }, [props.data]);

    const getSlogan = (string, styled) => {
        // return string
        let newString;
        if (string && styled) {
            let obj = styled.find(o => o.type === 'strong');
            if (!obj) {
                return string;
            }
            const replaced = string.slice(obj?.start, obj?.end);
            newString = string.replace(replaced, `<span class="slogan">${replaced}</span>`);
        }
        return newString;
    }

    return (
        <div>
            <div className={'hero-banner-text-container'}>
                <div className={'hero-banner-text-inner container'}>
                    {
                        data.rich_text_fieldtitle.map((item, index) =>
                            <p className={'hero-banner-text'} key={index} dangerouslySetInnerHTML={{ __html: getSlogan(item?.text, item?.spans) }}/>
                        )
                    }
                </div>
            </div>
            <div className={'hero-banner-image'}>
                <img src={data.image_field.url}/>
            </div>
        </div>
    )
}