import React, {useEffect, useState} from 'react';
import Section from "../section/section";
import {ReactComponent as Quote} from "../../img/icons/quote.svg"
import "./reviews.scss"

export default function Reviews(props) {
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])


    return (
        <Section id={data?.slice_type} type={'flex'}>
            <div className={'section-left'}>
                <h2 className={'section-flex-title'}>{data.primary?.title[0]?.text}</h2>
            </div>
            <div className={'section-right reviews-container'}>
                {
                    data?.items?.map((item, index) =>
                        <div key={index} className={'reviews-item'}>
                            <div className={'reviews-quote'}>
                                <Quote/>
                            </div>
                            <p className={'reviews-message'}>{item.message[0].text}</p>
                            <p className={'reviews-author'}>{item.author[0].text}</p>
                            <p className={'reviews-position'}>{item.position[0].text}</p>
                        </div>
                    )
                }
            </div>
        </Section>
    )
}